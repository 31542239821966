import { React, CenterWrapper, View, SEO, Page } from 'lib'
import { Theme, Settings, HTMLStylesDark } from 'app'
import TermsOfUse from './_terms.js'

function TermsOfUsePage(props) {
  return (
    <Page whiteBackground>
      <View style={styles.wrapper}>
        <SEO doNotTrack title={'Becon Terms Of Use'} description={`This page explains Becon App's Terms Of Use.`}/>
        <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content}>
          <TermsOfUse/>
        </CenterWrapper>
      </View>
    </Page>
  )
}


const styles = {
  wrapper: {
    minHeight: '100vh',
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    maxWidth: '100vw',
    marginTop: Theme.spacing(6),
    marginBottom: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(4),
      marginBottom: Theme.spacing(1),
    },
  },
  content: {
    ...HTMLStylesDark.plainText,
    display: 'flex',
    width: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
  },
}

export default TermsOfUsePage
